import { Button, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

interface AppliedTagProps {
  id: string;
  name: string;
  createdByUserId: string;
  color?: string;
  isApproved?: boolean;
  onRemove: (id: string) => void;
  onReview: (id: string, approved: boolean) => void;
}

const systemUserId = "00000000-0000-0000-0000-000000000001";

export const AppliedTag = ({ id, name, createdByUserId, color = '#6c757d', isApproved, onRemove, onReview}: AppliedTagProps) => {
  return (
    <Badge 
      bg="" 
      className="d-flex align-items-center"
      style={{ 
        backgroundColor: color,
        opacity: createdByUserId === systemUserId && (isApproved === null) ? 0.5 : 1,
        color: '#fff',
        border: 'none'
      }}
    >
      {name}
      {createdByUserId === systemUserId && (isApproved === null) ? (
        <>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Approve this AI-suggested tag</Tooltip>}
          >
            <Button
              size="sm"
              variant="link"
              className="p-0 ms-2 text-light"
              onClick={() => onReview(id, true)}
            >
              <i className="bi-hand-thumbs-up"></i>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Reject this AI-suggested tag</Tooltip>}
          >
            <Button
              size="sm"
              variant="link"
              className="p-0 ms-1 text-light"
              onClick={() => onReview(id, false)}
            >
              <i className="bi-hand-thumbs-down"></i>
            </Button>
          </OverlayTrigger>
        </>
      ) : (
        <Button
          size="sm"
          variant="link"
          className="p-0 ms-2 text-light"
          onClick={() => onRemove(id)}
        >
          <i className="bi-x"></i>
        </Button>
      )}
    </Badge>
  );
}; 