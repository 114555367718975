import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Popover from "react-bootstrap/Popover";

import { useLocation, useNavigate } from "react-router-dom";
import { sendAmplitudeData } from "../../services/amplitudeClient";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Tab from "react-bootstrap/Tab";
import { useEffect, useState } from "react";
import { parseISO, isValid } from "date-fns";
import { UsersTable } from "./components/UsersTable";
import {
  SentimentValue,
  SentimentValueInfo,
} from "../../components/SentimentValueIcon";
import { CallsTable } from "./components/CallsTable";
import { useLocalStorage } from "usehooks-ts";
import Badge from "react-bootstrap/Badge";
import {
  GET_TAGS,
} from "../../queries/Calls";
import { useQuery } from "@apollo/client";

export const TAB_KEYS = {
  users: "#users",
  phonecalls: "#phonecalls",
} as const;

export type TabsKeys = (typeof TAB_KEYS)[keyof typeof TAB_KEYS];

const TAB_KEYS_VALUES = new Set<string>([...Object.values(TAB_KEYS)]);

export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();

  //useSubscription(PHONECALL_UPDATES);
  //const auth = useAuth();

  const [search, setSearch] = useLocalStorage("search", "");
  const [startDate, setStartDate] = useLocalStorage("startDate", "");
  const [endDate, setEndDate] = useLocalStorage("endDate", "");
  const [newTag, setNewTag] = useState("");

  const [currentTab, setCurrentTab] = useState<TabsKeys>(TAB_KEYS.users);

  useEffect(() => {
    const locationHash = location.hash;

    sendAmplitudeData("dashboard", {});

    if (TAB_KEYS_VALUES.has(locationHash)) {
      setCurrentTab(locationHash as TabsKeys);
    } else {
      setCurrentTab("#phonecalls");
    }
  }, [location]);

  const [filterBookmarked, setFilterBookmarked] = useLocalStorage(
    "filterBookmarked",
    false,
  );

  const [filterSentimentValue, setFilterSentimentValue] = useLocalStorage(
    "filterSentimentValue",
    [],
  );

  const [filterCallDirection, setFilterCallDirection] = useLocalStorage(
    "filterCallDirection",
    [],
  );

  const [filterTags, setFilterTags] = useLocalStorage("filterTags", []);

  const [filterMinCallDuration, setFilterMinCallDuration] = useLocalStorage("filterMinCallDuration", {
    enabled: false,
    seconds: 0
  });

  const [filterHasComments, setFilterHasComments] = useLocalStorage("filterHasComments", false);

  const handleSetSearch = (search) => {
    sendAmplitudeData("dashboard-search", { query: search });
    setSearch(search);
  };

  const handleSetStartDate = (date) => {
    if (isValidDate(date))
      sendAmplitudeData("dashboard-date-start", { date: date });
    setStartDate(date.target.value);
  };

  const handleSetEndDate = (date) => {
    if (isValidDate(date))
      sendAmplitudeData("dashboard-date-end", { date: date });
    setEndDate(date.target.value);
  };

  const handleSetFilterBookmarked = (value: boolean) => {
    sendAmplitudeData("dashboard-filter-bookmarked", {
      value,
    });
    setFilterBookmarked(value);
  };

  const handleSetFilterSentimentValue = (value: SentimentValue) => {
    setFilterSentimentValue((prev) => {
      if (prev.includes(value)) {
        return prev.filter((v) => v !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const handleSetFilterTags = (tagId: string) => {
    if (tagId && !filterTags.includes(tagId) && filterTags.length < 5) {
      setFilterTags([...filterTags, tagId]);
      setNewTag("");
      setShowTagSuggestions(false);
    }
  };

  const handleRemoveTag = (tagIdToRemove: string) => {
    setFilterTags(filterTags.filter(tagId => tagId !== tagIdToRemove));
  };

  const [showUploadCallModal, setShowUploadCallModal] = useState(false);

  // const allowedUserIds = [
  //   "auth0|64f9d85a18765af7dd761f33",
  //   "auth0|64f9d88218765af7dd761f37",
  //   "auth0|64f9d7823d9e1162dc1173ee",
  //   "auth0|64f9d76618765af7dd761f22",
  //   "auth0|64f9d72f18765af7dd761f1f",
  //   "auth0|64f6ea7cc18541c842c72e46",
  // ];

  function isValidDate(dateString) {
    const date = parseISO(dateString);
    return isValid(date);
  }

  const setLocationHash = (newHash: TabsKeys) => {
    navigate(`${location.pathname}${newHash}`);
  };

  const UsersPopoverMenu = ({ callerId }) => {
    if (!callerId) return null;

    let callerIdSentiments = [];

    return (
      <Popover id="users-table-popover-menu">
        <Popover.Body>
          <Form>
            <Form.Label>Select filters for {callerId}</Form.Label>
            {SentimentValueInfo.map(({ value, iconClass }, i) => {
              return (
                <Form.Check
                  type="switch"
                  label={<i className={iconClass}></i>}
                  key={"filter" + value.toLowerCase()}
                  onChange={() => {
                    if (callerIdSentiments.includes(value)) {
                      callerIdSentiments = callerIdSentiments.filter(
                        (v) => v !== value,
                      );
                    } else {
                      callerIdSentiments.push(value);
                    }
                  }}
                ></Form.Check>
              );
            })}
            <Button
              size="sm"
              variant="link"
              className="p-0 d-block"
              onClick={() => {
                setLocationHash(TAB_KEYS.phonecalls);
                setFilterSentimentValue(callerIdSentiments);
                setSearch(callerId);
              }}
            >
              Show In Calls Table
            </Button>
          </Form>
        </Popover.Body>
      </Popover>
    );
  };

  const handleClearFilters = () => {
    setSearch("");
    setStartDate("");
    setEndDate(null);
    setFilterBookmarked(false);
    setFilterSentimentValue([]);
    setFilterCallDirection([]);
    setFilterTags([]);
    setFilterMinCallDuration({ enabled: false, seconds: 0 });
    setFilterHasComments(false);
  };

  const handleSetFilterCallDirection = (value: string) => {
    setFilterCallDirection((prev) => {
      if (prev.includes(value)) {
        return [];
      } else {
        return [value];
      }
    });
  };

  const { data: tagsData } = useQuery(GET_TAGS);
  const [showTagSuggestions, setShowTagSuggestions] = useState(false);

  const filteredTags = tagsData?.tags.filter(tag => 
    tag.name.toLowerCase().includes(newTag.toLowerCase()) &&
    !filterTags.includes(tag.id)
  ) || [];

  return (
    <Container fluid={true}>
      <Row className="my-5 mx-5">
        <Col xs="12" className="mb-4">
          <h2>Dashboard</h2>
        </Col>
        <Col xs="12" className="mb-4">
          <div className="d-flex flex-wrap align-items-center gap-3">
            <ButtonGroup size="sm">
              <OverlayTrigger
                key="tab-users-button"
                overlay={<Tooltip>Show users</Tooltip>}
              >
                <Button
                  active={currentTab === TAB_KEYS.users}
                  onClick={() => setLocationHash(TAB_KEYS.users)}
                >
                  <i className="bi-person"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                key="tab-phonecalls-button"
                overlay={<Tooltip>Show phonecalls</Tooltip>}
              >
                <Button
                  active={currentTab === TAB_KEYS.phonecalls}
                  onClick={() => setLocationHash(TAB_KEYS.phonecalls)}
                >
                  <i className="bi-telephone"></i>
                </Button>
              </OverlayTrigger>
            </ButtonGroup>

            <FormGroup className="flex-grow-1" style={{ minWidth: '150px', maxWidth: '400px' }}>
              <Form.Control
                value={search}
                size="sm"
                aria-label="case-search-input"
                aria-describedby="case-search-input"
                placeholder="Search name or phone number"
                onChange={(e) => handleSetSearch(e.target.value)}
              />
            </FormGroup>

            <InputGroup size="sm" style={{ minWidth: '120px', maxWidth: '200px' }}>
              <InputGroup.Text id="start-date">Start</InputGroup.Text>
              <Form.Control
                type="date"
                id="dateStart"
                value={startDate == null ? "" : startDate}
                aria-describedby="start-date"
                onChange={(e) => handleSetStartDate(e)}
              />
            </InputGroup>

            <InputGroup size="sm" style={{ minWidth: '120px', maxWidth: '200px' }}>
              <InputGroup.Text id="start-date">End</InputGroup.Text>
              <Form.Control
                type="date"
                id="dateEnd"
                value={endDate == null ? "" : endDate}
                onChange={(e) => handleSetEndDate(e)}
              />
            </InputGroup>
            
            <OverlayTrigger
                key="clear-filters"
                overlay={<Tooltip>Clear filters</Tooltip>}
              >
                <Button
                  variant="outline-secondary"
                  size="sm"
                  className="me-2"
                  onClick={handleClearFilters}
                >
                  <i className="bi-x-square"></i>
                </Button>
              </OverlayTrigger>

              {/* <OverlayTrigger overlay={<Tooltip>Upload Call</Tooltip>}>
                <Button
                  disabled={!allowedUserIds.includes(auth.user?.sub)}
                  onClick={() => setShowUploadCallModal(true)}
                  variant="outline-secondary"
                  size="sm"
                >
                  <i className="bi-plus-lg"></i>
                </Button>
              </OverlayTrigger> */}
          </div>

          {currentTab === TAB_KEYS.phonecalls && (
            <div className="d-flex flex-wrap gap-2 mt-3">
              <OverlayTrigger
                key="filter-bookmarked"
                overlay={<Tooltip>{filterBookmarked ? "Hide" : "Show"} Bookmarked</Tooltip>}
              >
                <Button
                  variant="outline-secondary"
                  size="sm"
                  active={filterBookmarked}
                  onClick={() => handleSetFilterBookmarked(!filterBookmarked)}
                >
                  <i className={filterBookmarked ? "bi-bookmark-fill" : "bi-bookmark"}></i>
                </Button>
              </OverlayTrigger>
              <ButtonGroup size="sm" className="me-2">
                {SentimentValueInfo.map(({ value, iconClass }, i) => {
                  const isFiltered = filterSentimentValue.includes(value);

                  return (
                    <OverlayTrigger
                      key={"filter" + value.toLowerCase()}
                      overlay={
                        <Tooltip>
                          {isFiltered ? "Hide" : "Show"} {value?.toLowerCase()}{" "}
                          sentiment
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="outline-secondary"
                        active={isFiltered}
                        onClick={() => handleSetFilterSentimentValue(value)}
                      >
                        <i
                          className={isFiltered ? `${iconClass}-fill` : iconClass}
                        ></i>
                      </Button>
                    </OverlayTrigger>
                  );
                })}
              </ButtonGroup>
              <ButtonGroup size="sm" className="me-2">
                <OverlayTrigger
                  key="filter-inbound-calls"
                  overlay={<Tooltip>Filter inbound calls</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    active={filterCallDirection.includes("INBOUND")}
                    onClick={() => handleSetFilterCallDirection("INBOUND")}
                  >
                    <i className="bi-telephone-inbound"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  key="filter-outbound-calls"
                  overlay={<Tooltip>Filter outbound calls</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    active={filterCallDirection.includes("OUTBOUND")}
                    onClick={() => handleSetFilterCallDirection("OUTBOUND")}
                  >
                    <i className="bi-telephone-outbound"></i>
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
              <ButtonGroup size="sm" className="me-2">
                <OverlayTrigger
                  key="filter-has-comments"
                  overlay={<Tooltip>{filterHasComments ? "Show all calls" : "Show calls with comments"}</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    active={filterHasComments}
                    onClick={() => setFilterHasComments(!filterHasComments)}
                  >
                    <i className="bi-chat-text"></i>
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
              <ButtonGroup size="sm" className="me-2 d-flex align-items-center">
                <OverlayTrigger
                  key="filter-min-call-duration"
                  overlay={<Tooltip>{filterMinCallDuration.enabled ? "Disable" : "Enable"} minimum call duration filter</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    active={filterMinCallDuration.enabled}
                    onClick={() => setFilterMinCallDuration(prev => ({ ...prev, enabled: !prev.enabled }))}
                  >
                    <i className="bi-clock"></i>
                  </Button>
                </OverlayTrigger>
                <Form.Control
                  type="number"
                  size="sm"
                  placeholder="Min seconds"
                  value={filterMinCallDuration.seconds}
                  onChange={(e) => setFilterMinCallDuration(prev => ({ 
                    ...prev, 
                    seconds: Math.max(0, parseInt(e.target.value) || 0),
                    enabled: true
                  }))}
                  style={{ width: '100px' }}
                  onClick={() => {
                    if (!filterMinCallDuration.enabled) {
                      setFilterMinCallDuration(prev => ({ ...prev, enabled: true }))
                    }
                  }}
                />
              </ButtonGroup>
              <InputGroup size="sm" style={{ minWidth: '150px', maxWidth: '200px' }}>
                <Form.Control
                  placeholder={filterTags.length >= 5 ? "Max tags reached" : "Filter by tag..."}
                  value={newTag}
                  onChange={(e) => {
                    setNewTag(e.target.value);
                    setShowTagSuggestions(true);
                  }}
                  onFocus={() => setShowTagSuggestions(true)}
                  onBlur={() => {
                    setTimeout(() => setShowTagSuggestions(false), 200);
                  }}
                  disabled={filterTags.length >= 5}
                />
                {showTagSuggestions && filteredTags.length > 0 && (
                  <div 
                    className="position-absolute bg-white border rounded shadow-sm w-100"
                    style={{ 
                      top: '100%', 
                      left: 0,
                      zIndex: 1000,
                      maxHeight: '200px',
                      overflowY: 'auto'
                    }}
                  >
                    {filteredTags.map(tag => (
                      <div
                        key={tag.id}
                        className="d-flex align-items-center p-2 cursor-pointer hover-bg-light"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleSetFilterTags(tag.id)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        <div 
                          className="rounded-circle me-2"
                          style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: tag.color || '#6c757d'
                          }}
                        />
                        {tag.name}
                      </div>
                    ))}
                  </div>
                )}
              </InputGroup>
              <div className="d-flex flex-wrap gap-1 align-items-center">
                {filterTags.map((tagId, index) => {
                  const tagData = tagsData?.tags.find(t => t.id === tagId);
                  return (
                    <Badge 
                      key={index} 
                      bg=""
                      className="d-flex align-items-center gap-1"
                      style={{ 
                        backgroundColor: tagData?.color || '#6c757d',
                        color: '#fff'
                      }}
                    >
                      {tagData?.name}
                      <i 
                        className="bi-x-circle" 
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleRemoveTag(tagId)}
                      ></i>
                    </Badge>
                  );
                })}
              </div>
            </div>
          )}
        </Col>
        <Col xs="12">
          <Tab.Container defaultActiveKey={currentTab} activeKey={currentTab}>
            <Tab.Content className="my-5">
              <Tab.Pane eventKey={TAB_KEYS.users}>
                {UsersTable({
                  UsersPopoverMenu,
                  currentTab,
                  startDate,
                  endDate,
                  isValidDate,
                })}
              </Tab.Pane>
              <Tab.Pane eventKey={TAB_KEYS.phonecalls}>
                {CallsTable({
                  search,
                  startDate,
                  endDate,
                  filterBookmarked,
                  filterSentimentValue,
                  filterCallDirection,
                  filterTags,
                  filterMinCallDuration,
                  filterHasComments,
                  showUploadCallModal,
                  setShowUploadCallModal,
                  isValidDate
                })}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
}
