import gql from "graphql-tag";

export const GET_USERS = gql`
  query getUsers($startDateTime: DateTime, $endDateTime: DateTime) {
    users {
      id
      callerId
      email
      firstName
      lastName
      phonecallSentimentStatistics(
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      ) {
        positivePhonecalls
        neutralPhonecalls
        negativePhonecalls
        unratedPhonecalls
      }
    }
  }
`;

export const GET_CONTACT_TYPES = gql`
  query {
    contactTypes {
      id
      name
    }
  }
`;

export const GET_SENTIMENT_TIMESERIES = gql`
  query GET_SENTIMENT_TIMESERIES(
    $startDate: DateTime!
    $endDate: DateTime!
    $groupBy: TimeSeriesGroupBy!
  ) {
    sentimentReportTimeSeries(
      startDate: $startDate
      endDate: $endDate
      groupBy: $groupBy
    ) {
      positiveSentimentPercentage
      neutralSentimentPercentage
      negativeSentimentPercentage
      dateTime
      label
      totalCalls
    }
  }
`;

export const ADD_TAG_TO_CALL = gql`
  mutation PhonecallTagCreate($phonecallId: UUID!, $tagId: UUID!) {
    phonecallTagCreate(input: { phonecallId: $phonecallId, tagId: $tagId }) {
      phonecall {
        id
        tags {
          id
          name
          color
        }
      }
    }
  }
`;

export const REMOVE_TAG_FROM_CALL = gql`
  mutation PhonecallTagDelete($phonecallId: UUID!, $tagId: UUID!) {
    phonecallTagDelete(input: { phonecallId: $phonecallId, tagId: $tagId }) {
      phonecall {
        id
        tags {
          id
          name
          color
        }
      }
    }
  }
`;

export const REVIEW_TAG_FROM_CALL = gql`
  mutation PhonecallTagReview($phonecallId: UUID!, $tagId: UUID!, $approved: Boolean!) {
    phonecallTagReview(input: { phonecallId: $phonecallId, tagId: $tagId, approved: $approved }) {
      phonecall {
        id
        tags {
          id
          name
          color
        }
      }
    }
  }
`;

export const GET_TAGS = gql`
  query GetTags {
    tags {
      id
      name
      color
    }
  }
`;