import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DateTime from "../../components/DateTime";
import CallContent from "./components/CallContent";
import { NavLink, useParams } from "react-router-dom";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { AudioPlayer } from "../../components/AudioPlayer";
import {   
  CREATE_PHONECALL_BOOKMARK,
  DELETE_PHONECALL_BOOKMARK 
} from "./gql/mutations";
import { GET_CALL } from "./gql/queries";
import {
  ADD_TAG_TO_CALL,
  REMOVE_TAG_FROM_CALL,
  GET_TAGS,
  REVIEW_TAG_FROM_CALL,
} from "../../queries/Calls";
import { Form } from "react-bootstrap";
import { PHONECALL_UPDATES } from "../../subscriptions/phonecall";
import { sendAmplitudeData } from "../../services/amplitudeClient";
import { useState } from "react";
import { Tag } from "../../components/Tag";
import { AppliedTag } from "../../components/AppliedTag";

export const Call = () => {
  const params = useParams();
  const id = params.id;
  const { loading, data, refetch } = useQuery(GET_CALL, {
    variables: { id },
  });

  //const [deletePhonecallFunction] = useMutation(DELETE_PHONECALL);
  //const [createTranscriptionFunction] = useMutation(CREATE_TRANSCRIPTION);
  const [createBookmarkFunction] = useMutation(CREATE_PHONECALL_BOOKMARK);
  const [deleteBookmarkFunction] = useMutation(DELETE_PHONECALL_BOOKMARK);
  const isCheckboxChecked = data?.phonecall.follows.length > 0;
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [seekTo, setSeekTo] = useState<((time: number) => void) | null>(null);
  const [newTag, setNewTag] = useState("");
  const [isAddingTag, setIsAddingTag] = useState(false);

  // Add mutations for tags
  const [addTagFunction] = useMutation(ADD_TAG_TO_CALL);
  const [removeTagFunction] = useMutation(REMOVE_TAG_FROM_CALL);
  const [reviewTagFunction] = useMutation(REVIEW_TAG_FROM_CALL);

  // Add query for available tags
  const { data: tagsData } = useQuery(GET_TAGS);
  const [showTagSuggestions, setShowTagSuggestions] = useState(false);

  // Filter tags based on input
  const filteredTags = tagsData?.tags.filter(tag => 
    tag.name.toLowerCase().includes(newTag.toLowerCase()) &&
    !data?.phonecall.tags?.some(existingTag => existingTag.id === tag.id)
  ) || [];

  useSubscription(PHONECALL_UPDATES);

//  const handleDelete = async (phonecallId) => {
//    sendAmplitudeData("phonecall-delete", { id: phonecallId });
//    await deletePhonecallFunction({
//      variables: {
//        phonecallId,
//      },
//    });
//    navigate(`/dashboard`);
//  };
//
//  const handleCreateTranscription = async (phonecallId) => {
//    sendAmplitudeData("phonecall-transcribe", { id: phonecallId });
//    await createTranscriptionFunction({
//      variables: {
//        id: phonecallId,
//      },
//    });
//  };

  const handleSetBookmarked = async (phonecallId: string, checked: boolean) => {
    if (!checked) {
      sendAmplitudeData("phonecall-bookmark-delete", { id: phonecallId });
      await deleteBookmarkFunction({
        variables: {
          phonecallId: phonecallId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id,
            __typename: "UserPhonecallFollow",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
      await refetch();
    } else {
      sendAmplitudeData("phonecall-bookmark-create", { id: phonecallId });
      await createBookmarkFunction({
        variables: {
          phonecallId: phonecallId,
        },
      });
      await refetch();
    }
    //setBookmarked(checked);
  };

  const handleMouseUpOnTranscription = () => {
    console.log(`Selected text: ${window.getSelection().toString()}`);
  };

  const handleAddTag = async (tagId?: string, tagName?: string) => {
    try {
      setIsAddingTag(true);
      sendAmplitudeData("phonecall-tag-add", { id: id });
      await addTagFunction({
        variables: {
          phonecallId: id,
          name: tagName || newTag.trim(),
          ...(tagId && { tagId })
        },
      });
      setNewTag("");
      setShowTagSuggestions(false);
      await refetch();
    } catch (error) {
      console.error("Error adding tag:", error);
    } finally {
      setIsAddingTag(false);
    }
  };

  const handleRemoveTag = async (tagId: string) => {
    try {
      sendAmplitudeData("phonecall-tag-remove", { id: id });
      await removeTagFunction({
        variables: {
          phonecallId: id,
          tagId: tagId
        },
      });
      await refetch();
    } catch (error) {
      console.error("Error removing tag:", error);
    }
  };

  const handleReviewTag = async (tagId: string, approved: boolean) => {
    try {
      await reviewTagFunction({ variables: { phonecallId: id, tagId, approved } });
      await refetch();
    } catch (error) {
      console.error("Error reviewing tag:", error);
    }
  };

  return (
    <div className="container-fluid">
    <div className="d-flex">
      <div className="py-4 px-3 flex-grow-1">
        <Row className="mb-3">
          <Col xs="12">
            <Breadcrumb>
              <NavLink to="/dashboard" className="breadcrumb-item">
                Dashboard
              </NavLink>
              <Breadcrumb.Item active>Call</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {!loading && (
          <div style={{ justifyContent: "center", display: "flex"}}>
          <Row className="mb-3" style={{ maxWidth: "1200px" }}>
            <Col lg="6">
              <dl className="row">
                <dt className="col-sm-3">Caller</dt>
                <dd className="col-sm-9">
                  <div className="d-flex">
                    {data?.phonecall.callingAgent ||
                      data?.phonecall.callingPartyNumber}
                  </div>
                </dd>
                <dt className="col-sm-3">Dialled</dt>
                <dd className="col-sm-9">
                  <div className="d-flex">
                    {data?.phonecall.dialledAgent ||
                      data?.phonecall.dialledPartyNumber}
                  </div>
                </dd>
                <dt className="col-sm-3">When</dt>
                <dd className="col-sm-9">
                  <div className="d-flex">
                    {data ? (
                      <DateTime value={data?.phonecall.eventDateTime} />
                    ) : (
                      "-"
                    )}
                  </div>
                </dd>
                {/*<dt className="col-sm-3">Status</dt>*/}
                {/*<dd className="col-sm-9">*/}
                {/*  <Badge*/}
                {/*    bg={*/}
                {/*      data?.phonecall.status === "COMPLETE"*/}
                {/*        ? "success"*/}
                {/*        : "secondary"*/}
                {/*    }*/}
                {/*    className="text-lowercase rounded-5"*/}
                {/*  >*/}
                {/*    {data?.phonecall.status}*/}
                {/*  </Badge>*/}
                {/*</dd>*/}
                <dt className="col-sm-3">Bookmark</dt>
                <dd className="col-sm-9">
                  <div className="d-flex">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={isCheckboxChecked}
                      onChange={(e) =>
                        handleSetBookmarked(data?.phonecall.id, e.target.checked)
                      }
                    />
                  </div>
                </dd>
                <dt className="col-sm-3">Tags</dt>
                <dd className="col-sm-9">
                  <div className="d-flex gap-2 position-relative mb-2">
                    <div className="position-relative w-100">
                      <Form.Control
                        size="sm"
                        type="text"
                        placeholder="Add new tag"
                        value={newTag}
                        disabled={isAddingTag}
                        onChange={(e) => {
                          setNewTag(e.target.value);
                          setShowTagSuggestions(true);
                        }}
                        onFocus={() => setShowTagSuggestions(true)}
                        onBlur={() => {
                          setTimeout(() => setShowTagSuggestions(false), 200);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && newTag.trim()) {
                            e.preventDefault();
                            handleAddTag();
                          }
                        }}
                      />
                      {isAddingTag && (
                        <div 
                          className="position-absolute top-50 end-0 translate-middle-y me-2"
                          style={{ pointerEvents: 'none' }}
                        >
                          <i className="bi-spinner bi-spin text-secondary"></i>
                        </div>
                      )}
                    </div>
                    
                    {showTagSuggestions && filteredTags.length > 0 && (
                      <div 
                        className="position-absolute bg-white border rounded shadow-sm w-100"
                        style={{ 
                          top: '100%', 
                          zIndex: 1000,
                          maxHeight: '200px',
                          overflowY: 'auto'
                        }}
                      >
                        {filteredTags.map(tag => (
                          <Tag
                            key={tag.id}
                            name={tag.name}
                            color={tag.color}
                            showDot={true}
                            onClick={() => handleAddTag(tag.id, tag.name)}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-wrap gap-2">
                    {data?.phonecall.tags?.map((tag) => (
                      <AppliedTag
                        key={tag.id}
                        id={tag.id}
                        name={tag.name}
                        createdByUserId={tag.createdByUserId}
                        color={tag.color}
                        isApproved={tag.isApproved}
                        onRemove={handleRemoveTag}
                        onReview={handleReviewTag}
                      />
                    ))}
                  </div>
                </dd>
              </dl>
            </Col>
            <Col lg="6" className={"text-end"}></Col>
            <br />
            <Col xs="12" className="mb-4" style={{ minHeight: 60 }}>
              <AudioPlayer
                phonecallId={data?.phonecall.id}
                onTimeUpdate={(time) => setCurrentTime(time)}
                onPlayingStateChange={(playing) => setIsPlaying(playing)}
                onSeekRequest={(seekFn) => setSeekTo(() => seekFn)}
              />
            </Col>
            <CallContent
              data={data}
              handleMouseUpOnTranscription={handleMouseUpOnTranscription}
              currentTime={currentTime}
              isPlaying={isPlaying}
              onSeekTime={seekTo}
            />
          </Row>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};
