import React from 'react';

interface SpeakerLineProps {
  speaker: number;
  timestamp: number;
  children: any;
  onTimeClick?: (time: number) => void;
}

const SpeakerLine: React.FC<SpeakerLineProps> = ({
    speaker,
    timestamp,
    children,
    onTimeClick
}) => {

  const colorDictionary: Record<number, string> = {
    1: '#E5E5FF', // Light Blue
    2: '#FFFFE5', // Light Yellow
    3: '#FFE5FF', // Light Magenta
    4: '#FFE5E5', // Light Red
    5: '#E5FFE5', // Light Green
  };

  function formatTime(seconds: number): string {
      const mins = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }

  speaker = speaker +1;

  const backgroundColor = colorDictionary[speaker] || '#F0F0F0'; // Default to light gray if no color is found
  const time = formatTime(timestamp);
  return (
<div className="row mb-3">
  <div className="col-auto">
    <div
      className="d-flex align-items-center flex-shrink-0 justify-content-center border rounded-circle"
      style={{
        width: '45px',
        height: '45px',
        backgroundColor: backgroundColor
      }}>{speaker}</div>
  </div>
  <div className="col">
    <div>
      <span
        className="text-muted small"
        onClick={() => onTimeClick?.(timestamp)}
        role="button">
        {time ?? ""}
      </span>
    </div>
    {children}
  </div>
</div>
  );
};

export default SpeakerLine;
